<template>
  <div class="sld_invoiceInfo_list">
    <!-- <MemberTitle :memberTitle="L['发票管理']"></MemberTitle> -->
    <div class="container">
      <h3>{{ L["发票详情"] }}</h3>

      <div class="layer6 flex-col" v-if="detailInfo">
        <div class="box1 flex-col">
          <span class="txt13">发票信息</span>
          <div class="layer7 flex-col">
            <div class="mod2 flex-col">
              <div class="wrap3 flex-row">
                <span class="txt14">
                  <span style="white-space:nowrap;line-height:20px">发票号：</span>
                  <span style="line-height:20px">{{ detailInfo.invoiceCode ? detailInfo.invoiceCode : '--' }}</span>
                </span>
                <span class="word24">发票金额：¥{{ detailInfo.invoiceAmount }}</span>
              </div>
              <div class="wrap4 flex-col"></div>
              <div class="wrap5 flex-row">
                <span class="txt15">
                  发票性质：{{ detailInfo.type == 1 ? '电子' : '纸质' }}发票
                  <div v-if="detailInfo.invoiceUrl">
                    <template v-for="item in detailInfo.invoiceUrl.split(',')" :key="item">
                      <i @click="hrefJump(item)" v-if="detailInfo.type == '1' && detailInfo.state == 2"
                        class="iconfont iconshangchuan2 label10"></i>
                    </template>
                  </div>
                </span>
                <span class="word25" @click="lookLogistics" v-if="detailInfo.type == '2' && detailInfo.state == 2">
                  <span>查看物流</span>
                </span>
                <span class="word26">发票状态：{{ detailInfo.stateValue }}</span>
              </div>
              <div class="wrap6 flex-col"></div>
              <div class="wrap7 flex-row">
                <span class="txt16">发票类型：{{ detailInfo.invoiceTypeValue }}</span>
                <span class="txt171"
                  :title="detailInfo.invoiceTitle && detailInfo.invoiceTitle.length > 26 ? detailInfo.invoiceTitle : ''">
                  发票抬头：{{ detailInfo.invoiceTitle }}</span>
              </div>
              <div class="wrap8 flex-col"></div>
              <span class="txt18">申请时间：{{ detailInfo.createTime ? detailInfo.createTime : '--' }}</span>
              <div class="wrap8 flex-col"></div>
              <div class="txt1889 flex_row_start_start">
                <div>备注信息：</div>
                <div>{{ detailInfo.memberRemark ? detailInfo.memberRemark : '--' }}</div>
              </div>
            </div>
            <div class="mod3 flex-col"></div>
          </div>
          <span class="txt19">收票信息</span>
          <div class="layer8 flex-col" v-if="detailInfo.type == '2'">
            <div class="section6 flex-col">
              <div class="layer9 flex-row">
                <span class="info13" v-if="detailInfo.memberInvoiceAddress">收票人：{{
                  detailInfo.memberInvoiceAddress.memberName
                }}</span>
                <span class="info13" v-else>收票人：--</span>
                <div class="main2 flex-col"></div>
                <span class="info14" v-if="detailInfo.memberInvoiceAddress">联系电话：{{
                  detailInfo.memberInvoiceAddress.telMobile
                }}</span>
                <span class="info14" v-else>联系电话：--</span>
              </div>
              <div class="layer10 flex-col"></div>
              <span class="word27" v-if="detailInfo.memberInvoiceAddress">
                详细地址：{{ detailInfo.memberInvoiceAddress.addressAll }}{{ detailInfo.memberInvoiceAddress.detailAddress }}
              </span>
              <span class="word27" v-else>
                详细地址：--
              </span>
            </div>
          </div>
          <div class="layer81 flex-col" v-else>邮箱：{{ detailInfo.receiveEmail }}</div>
          <span class="word28">订单信息</span>
          <div class="mod5_box">
            <div class="mod5 flex-col">
              <div class="outer10 flex-row">
                <span class="word29 word30">订单号</span>
                <span class="word29 word31">采购单号</span>


                <span class="word29 info16">订单金额</span>
                <span class="word29 info15">供应商</span>
                <span class="word29 word32">支付时间</span>
              </div>
            </div>
            <template v-if="detailInfo.invoiceOrderList.length > 0">
              <template v-for="item in detailInfo.invoiceOrderList" :key="item">
                <div class="mod6 flex-col">
                  <div class="outer11 flex-row">

                    <span class="word35 word37">
                      <router-link :to="`/member/order/info?orderSn=${item.orderSn}`">
                        {{ item.orderSn }}
                      </router-link>
                    </span>
                    <span class="word35 info17">{{ item.purchaseSn ? item.purchaseSn : '--' }}</span>

                    <span class="word35 txt22">￥{{ item.orderAmount }}</span>
                    <span class="word35 txt21">{{ item.storeName }}</span>
                    <span class="word35 word38">{{ item.payTime ? item.payTime : '--' }}</span>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <SldCommonEmpty totalWidth="1003" tip="暂无订单信息" />
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- 查看物流弹窗 start -->
    <el-dialog :title="L['物流信息']" v-model="show_logistics" customClass="select_reason_width" :before-close="handleClose"
      lock-sroll="false">
      <div class="logistics_info">
        <p>{{ logistics_info.data.type == 1 ? L['联系电话'] : L['承运人'] }}：{{ logistics_info.data.expressName }}</p>
        <p>{{ logistics_info.data.type == 1 ? L['联系人'] : L['运单号'] }}：{{ logistics_info.data.expressNumber }}</p>
      </div>
      <div class="logistics_list_con">
        <el-timeline>
          <el-timeline-item v-for="(logistics, index) in logistics_info.data.routeList" :key="index"
            :timestamp="logistics.acceptStation ? logistics.acceptStation : logistics.remark">{{
  logistics.acceptTime
            }}</el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
    <!--  查看物流弹窗 end  -->
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import MemberTitle from "@/components/MemberTitle";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from 'element-plus';
import SldCommonEmpty from '@/components/SldCommonEmpty'
export default {
  name: "FinaceInvoiceInfo",
  components: {
    MemberTitle,
    SldCommonEmpty
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const route = useRoute();
    const router = useRouter();
    const addressId = ref(route.query.addressId);
    const invoiceId = ref(route.query.invoiceId);
    const invoiceProductIds = ref(route.query.invoiceProductIds);
    const detailInfo = ref();
    const show_logistics = ref(false);
    const logistics_info = reactive({ data: {} });

    const getInfo = () => { //获取详情
      let param = {
        addressId: addressId.value,
        invoiceId: invoiceId.value,
        invoiceProductIds: invoiceProductIds.value
      }
      proxy
        .$get('v3/business/front/invoice/detail', param)
        .then(res => {
          if (res.state == 200) {
            detailInfo.value = res.data;
          } else {
            ElMessage.error('发票详情数据有误');
            router.back();
          }
        })
    };

    //显示查看物流信息
    const lookLogistics = () => {
      proxy
        .$get("v3/business/front/logistics/invoice/getTrace", {
          invoiceId: invoiceId.value
        })
        .then(res => {
          if (res.state == 200) {
            show_logistics.value = true;
            logistics_info.data = res.data;
          } else {
            ElMessage(res.msg);
          }
        })
    };

    //查看电子发票
    const hrefJump = (href) => {
      if (href) {
        window.open(href, '_blank');
      }
    };

    onMounted(() => {
      getInfo();
    });

    return {
      L,
      addressId,
      invoiceId,
      invoiceProductIds,
      detailInfo,
      getInfo,
      show_logistics,
      logistics_info,
      lookLogistics,
      hrefJump,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/finace/invoiceInfo.scss";
</style>
<style lang="scss">
.sld_invoiceInfo_list {
  .sld_common_empty {
    height: 281px !important;
    padding-top: 80px !important;
  }
}
</style>